import {ref} from 'vue'
import {defineStore} from 'pinia'
import isMobile from '@/utils/isMobile'
import {
  BreakdownSlideoutController,
  SlideoutController
} from '@/utils/SlideoutController'
import * as Fathom from 'fathom-client'
import {useUserLoginStore} from '@/stores/userLogin'

export enum ModalContent {
  companies,
  messages,
  filters
}

/**
  * Contains the index of the func/headcount that was clicked on, as well as
  * the func/headcount itself.
  *
  * Not to be confused with HeadcountChartClickTarget, this is after the
  * index to func/headcount conversion
*/

export const useAppStateStore = defineStore('appState', () => {
  const appLoading = ref(true)
  const appReady = ref(false)
  const modalOpen = ref(0)

  const breakdownSlideoutController = new BreakdownSlideoutController()
  const introSlideoutController = new SlideoutController()
  const slideouts = [breakdownSlideoutController, introSlideoutController]

  breakdownSlideoutController.setSlideouts(slideouts)
  introSlideoutController.setSlideouts(slideouts)

  const modalContent = ref(isMobile() ? ModalContent.filters : ModalContent.companies)
  const modalHasTabs = ref(false)
  const mobileMenuOpen = ref(false)
  const hasEnoughCompanies = ref(true)

  const notEnoughCompanies = () => hasEnoughCompanies.value = false
  const enoughCompanies = () => hasEnoughCompanies.value = true

  const openMobileMenu = () => {
    mobileMenuOpen.value = true
  }

  const closeMobileMenu = () => {
    mobileMenuOpen.value = false
  }

  const appendIpuiToURL = (url: string) => {
    const currentUrl = new URL(url);
    let prefix = ''
    if (currentUrl.hash && currentUrl.hash[currentUrl.hash.length - 1] !== '&') {
      prefix = '&'
    }

    if (useUserLoginStore().ipui) {
      // Add encrypted email to the URL
      currentUrl.hash += `${prefix}ipui=${useUserLoginStore().ipui}`;
    }

    return currentUrl.href;
  }

  const fathomTrackPageView = () => {
    Fathom.trackPageview({url: appendIpuiToURL(window.location.href)});
  }
  
  const googleTrackPageView = () => {
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        'page_location': appendIpuiToURL(window.location.href),
      })
    }
  }

  const closeModal = () => {
    modalOpen.value = 0
    fathomTrackEvent(`teamplan:modal:closed`)
  }

  const fathomTrackEvent = (eventName: string) => {
    if (useUserLoginStore().ipui) {
      eventName = `${eventName}:ipui=${useUserLoginStore().ipui}`
    }
    Fathom.trackEvent(eventName)
  }

  const googleTrackEvent = (eventName: string) => {
    if(window.gtag) {
      window.gtag('event', eventName)
    }
  }

  const trackEvent = (eventName: string) => {
    fathomTrackEvent(eventName)
    googleTrackEvent(eventName)
  }

  const trackPageView = () => {
    fathomTrackPageView()
    googleTrackPageView()
  }

  const openCompaniesModal = () => {
    if (useUserLoginStore().popupOpen) return
    modalContent.value = ModalContent.companies
    modalHasTabs.value = true
    modalOpen.value = Math.random()
    // breakdownSlideoutController.close() should work, but it doesn't... the watcher doesn't see the change.
    useAppStateStore().breakdownSlideoutController.close()

    trackEvent(`teamplan:modal:opened:companies`)
  }

  const openMessagesModal = () => {
    if (useUserLoginStore().popupOpen) return
    modalContent.value = ModalContent.messages
    modalHasTabs.value = false
    modalOpen.value = Math.random()

    trackEvent(`teamplan:modal:opened:messages`)
  }

  const openFiltersModal = () => {
    if (useUserLoginStore().popupOpen) return
    modalContent.value = ModalContent.filters
    modalHasTabs.value = true
    modalOpen.value = Math.random()

    trackEvent(`teamplan:modal:opened:filters`)
  }

  // If we need to have more than one piece of content in the modal,
  // just update this to check an array with inlc
  const modalHasContent = (content: ModalContent) => {
    return modalContent.value === content
  }

  return {
    breakdownSlideoutController,
    introSlideoutController,
    appLoading,
    appReady,
    modalOpen,
    modalContent,
    modalHasContent,
    modalHasTabs,
    openMessagesModal,
    openCompaniesModal,
    openFiltersModal,
    closeModal,
    mobileMenuOpen,
    openMobileMenu,
    closeMobileMenu,
    hasEnoughCompanies,
    enoughCompanies,
    notEnoughCompanies,
    fathomTrackEvent,
    fathomTrackPageView,
    googleTrackPageView,
    googleTrackEvent,
    trackEvent,
    trackPageView
  }
})
