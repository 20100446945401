import { prefersReducedMotion } from '@/utils/reducedMotion';
import anime, { type AnimeParams } from 'animejs'

export default function animate(target: HTMLElement | undefined, options: AnimeParams) {
  if (target === undefined) return;

  const default_options = {
    targets: target,
    duration: 250,
    easing: 'easeOutQuad'
  }

  // Combine defaults and passed in options.
  options = {
    ...default_options,
    ...options
  }

  // If user prefers reduced motion, set duration to 0.
  if (prefersReducedMotion()) {
    options.duration = 0
  }

  return anime(options)
}
