import { useCmsTextStore } from "@/stores/cmsText";

function useCmsText(key?: string) {
  const cmsTextStore= useCmsTextStore()
  if (!key) return '';
  return cmsTextStore.text[key] ?? ''
}

function cmsTextReady() {
  const cmsTextStore = useCmsTextStore()
  return cmsTextStore.ready
}

export { useCmsText, cmsTextReady }
