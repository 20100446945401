import { ref, watch } from 'vue'
import { defineStore } from 'pinia'
import businessModels from '@/data/filters/businessModels.json'
import hQLocations from '@/data/filters/HQLocations.json'
import headcountBy from '@/data/filters/headcountBy.json'
import hiringRange from '@/data/filters/hiringRange.json'
import leadershipAtHeadcount from '@/data/filters/leadershipAtHeadcount.json'
import companies from '@/data/filters/companies.json'
import hiringStage from '@/data/filters/hiringStage.json'
import router from '@/router'
import useIsMobile from '@/composables/useIsMobile'

export interface FilterOption {
  [key: string]: any | undefined,
  label: string,
  value: string
}

export interface FilterInterface {
  name: string,
  id: string,
  default: string[],
  options: FilterOption[]
}

export const useFiltersStore = defineStore('filters', () => {
  const selected = ref<{ [key: string]: string[] }>({})
  const selectedLabels = ref<{ [key: string]: string[] }>({})
  const isMobile = useIsMobile()

  const filters: { [key: string]: FilterInterface | undefined; } = {
    companies: companies,
    businessModels: businessModels,
    hqLocations: hQLocations,
    headcountBy: headcountBy,
    hiringRange: hiringRange,
    leadershipAtHeadcount: leadershipAtHeadcount,
    hiringStage: hiringStage
  }

  // Variable default headcountBy based on device.
  if (filters.headcountBy) {
    filters.headcountBy.default = [filters.headcountBy.options[isMobile.value ? 1 : 0].value]
  }

  // Alphabetise companies.options.
  filters.companies?.options.sort((a, b) => a.label.localeCompare(b.label))

  const setUrlFilters = (newSelected: { [key: string]: string[] }) => {
    selected.value = newSelected

    const valuesStringGenerator = (valueOrUnsortedValues: string[]) => {
      return valueOrUnsortedValues.map(encodeURIComponent).sort().join(',')
    }

    const getSelectedFiltersStr = () =>
      Object.entries(selected.value).reduce((acc, [key, value]) => {
        if (filters[key]?.default.includes('*') &&
          filters[key]?.options.every(f =>
            value.find(selected => f.value === selected)
          )
        ) return acc

        const values = valuesStringGenerator(value);

        if (values != filters[key]?.default.sort().map(encodeURIComponent).join(',')) {
          return acc + `${key}=${values}&`
        }

        return acc
      }, '')

    // check if hash is the same before replacing
    if (getSelectedFiltersStr() === router.currentRoute.value.hash.slice(1)) return

    // Replace the URL hash with the selected filters.
    const path = window.location.pathname.split('/').splice(2).join('/')
    if (getSelectedFiltersStr() === '') {
      router.replace({ path })
      return
    }
    router.replace({ path, hash: `#${getSelectedFiltersStr()}` })
  }

  for (const filterKey in filters) {
    const filter = filters[filterKey]

    if (!filter || !filter.default) continue

    for (const defaultFilter of filter.default) {
      if (defaultFilter === '*') {
        // all values are selected by default
        selected.value[filterKey] = filter.options.map((option) => option.value)
        continue
      }
      const option = filter.options?.find((option) => option.value === defaultFilter);
      if (!option) continue;
      selected.value[filterKey] = [...selected.value[filterKey] || [], option.value]
    }
  }

  watch(() => selected.value, () => {
    // Store the selected labels for display.
    selectedLabels.value = {}
    for (const i in filters) {
      const filter = filters[i]
      if (!filter || !filter?.options) continue
      filter.options.forEach((option) => {
        if (selected.value[i]?.includes(option.value)) {
          selectedLabels.value[i] = [...selectedLabels.value[i] || [], option.label]
        }
      })
    }
  }, { deep: true, immediate: true })

  watch(() => selected.value, () => {
    // check if the current hash is the same as the selected filters
    const hash = router.currentRoute.value.hash.replace('#', '')
    const urlParams = new URLSearchParams(hash);
    const params = Object.entries(Object.fromEntries(urlParams))
      .reduce((acc, [key, value]) => ({
        ...acc,
        [key]: value.split(",").filter(Boolean)
      }), {})

    if (JSON.stringify(params) === JSON.stringify(selected.value)) return

    setUrlFilters(selected.value)
    // Update the URL hash when the selected filters change.
  }, { deep: true })

  return { filters, selected, setUrlFilters, selectedLabels }
})
