<template>
  <ol ref="logosRef" class="flex items-center opacity-30 pointer-events-none" :class="{
    'flex-col space-y-56 lg:space-y-40': vertical,
    'absolute space-x-12 lg:space-x-28 pl-20': !vertical,
  }">
    <template v-for="index in [0, 1]" :key="index">
      <template v-if="index === 0 || (index === 1 && measurementTaken)">
        <li v-for="company in companies" :key="company.label" class="w-auto text-center block">
          <img :src="loadedLogos[company.value]" :alt="company.label + ' logo'" loading="lazy"
            class="max-w-[12rem] grayscale origin-top-left inline" :class="{
              'brightness-0 opacity-75': company.logo_light,
              'h-6': company.logo_size == 1,
              'h-7': company.logo_size == 2,
              'h-10': company.logo_size == 3
            }">
        </li>
      </template>
    </template>
  </ol>
</template>

<script setup lang="ts">
import animate from "@/composables/animate";
import { ref, } from "vue";
import { useFiltersStore, type FilterOption } from "@/stores/filters";
import logos from '@/assets/logos'

const filtersStore = useFiltersStore()
const logoAnimation = ref<ReturnType<typeof animate>>()
const logosRef = ref<HTMLElement | undefined>()
const measurementTaken = ref(false)

const props = defineProps({
  vertical: {
    type: Boolean,
    default: false
  }
})

const fixedCompanies = [
  "spotify",
  "airbnb",
  "stripe",
  "pinterest",
  "datadog",
  "zillow",
  "warby parker",
  "roblox",
  "patreon",
  "figma",
  "figs",
  "discord",
  "etsy",
  "wiz",
  "robinhood",
  "glossier",
  "doordash",
  "scale",
  "canva",
  "calm"
]

const loadedLogos = ref<{ [key: string]: any }>({})

// Create a filtered list of companies that are in the fixedCompanies list.
const companies = filtersStore.filters['companies']?.options.filter((company) => fixedCompanies.includes(company.value))

// Order the companies to match the fixedCompanies list.
companies?.sort((a, b) => fixedCompanies.indexOf(a.value) - fixedCompanies.indexOf(b.value))

if (!companies) {
  throw new Error('No companies found')
}

// Build a map of company names to their build logo src.
companies.map(async (logo: FilterOption) => {
  const logo_name = logo.logo_src as keyof typeof logos
  const src: string | undefined = logos[logo_name]

  loadedLogos.value[logo.value] = src
})

/**
 * Preload images to ensure they are available when the animation starts.
 *
 * @param companies
 */
const preloadImages = function (companies: FilterOption[]): Promise<void[]> {
  const promises: Promise<void>[] = [];
  for (const company of companies) {

    const promise = new Promise<void>((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve();
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = loadedLogos.value[company.value]
    });

    promises.push(promise);
  }

  return Promise.all(promises);
}

preloadImages(companies)
  .then(() => {
    let travelDistance = 0
    let animationParams = { translateX: '0px', translateY: '0px', duration: 0, easing: 'linear', loop: true }

    if (props.vertical) {
      travelDistance = (logosRef.value?.offsetHeight || 0) + 166
      measurementTaken.value = true
      animationParams.translateY = `-${travelDistance}px`
      animationParams.duration = companies.length * 1000
    }
    else {
      travelDistance = (logosRef.value?.offsetWidth || 0) + 32
      measurementTaken.value = true
      animationParams.translateX = `-${travelDistance}px`
      animationParams.duration = companies.length * 2000
    }

    logoAnimation.value = animate(logosRef.value, animationParams)
  })
  .catch((error) => {
    console.error('Error preloading images:', error);
  });

defineExpose({
  logoAnimation
});

</script>

