<script setup lang="ts">
import { RouterView, useRouter } from 'vue-router'
import { cmsTextReady, useCmsText } from "@/composables/useCmsText";
import { useHead } from '@unhead/vue'
import { onMounted, ref, watch } from "vue";
import AppLoader from "@/components/AppLoader.vue";
import { ivColors } from '@/data/colors';
import { useLoaderStore } from '@/stores/loader';
import * as Fathom from 'fathom-client';
import { useAppStateStore } from '@/stores/appState';

const chartJs = () => import('chart.js')
const TeamsView = () => import('@/views/TeamsView.vue')
const HiresView = () => import('@/views/HiresView.vue')
const LeadersView = () => import('@/views/LeadersView.vue')
const IntroView = () => import('@/views/IntroView.vue')
const AppView = () => import('@/views/AppView.vue')

const loaderStore = useLoaderStore()

// lazy load the views, wait for them to import
const lazyLoadLoaded = ref(false)

loaderStore.addDependency(lazyLoadLoaded)

// only load the big content after the loader has started animated
onMounted(() => {
  setTimeout(() => {
    Promise.all(
      [
        TeamsView(),
        HiresView(),
        LeadersView(),
        IntroView(),
        AppView(),
        new Promise((resolve, reject) => {
          // lazy load chartjs and set defaults when loaded
          chartJs().then(({ Chart, registerables }) => {
            // Set the global chart defaults for the app
            Chart.defaults.font.family = font.family;
            Chart.defaults.color = ivColors.functionalBlack;
            Chart.defaults.font.size = font.size;
            Chart.register(...registerables)
            resolve(chartJs())
          })
            .catch(reject)
        })
      ]
    ).then(() => {
      lazyLoadLoaded.value = true
    })
  }, 100)
})

watch(() => cmsTextReady(), () => {
  useHead({
    title: useCmsText('meta-title'),
    meta: [
      {
        name: 'description',
        content: useCmsText('meta-description')
      }
    ],
    link: [
      {
        rel: 'canonical',
        href: useCmsText('meta-canonical')
      }
    ]
  })
})

const fathomIdElement = document.getElementById('FATHOM_ID')
const fathomId = fathomIdElement ? JSON.parse(fathomIdElement.innerText) : null
if (fathomId) {
  Fathom.load(fathomId, { auto: false, spa: 'hash' })
}

const router = useRouter()

const appStateStore = useAppStateStore()

const prevPathAndHash = ref()

watch(() => [router.currentRoute.value.path, router.currentRoute.value.hash], () => {
  // close the slideout if the route changes and the route is not /teams
  if (router.currentRoute.value.path !== '/teams') {
    appStateStore.breakdownSlideoutController.close()
  }

  const pathHash = router.currentRoute.value.path + router.currentRoute.value.hash

  // This watcher sometimes runs when it's the same values, so we need to check
  if (prevPathAndHash.value === pathHash) {
    return
  }

  prevPathAndHash.value = pathHash
  if (router.currentRoute.value.path !== '/') {
    appStateStore.trackPageView()
  }
}, { immediate: true })

const font = { family: 'Helvetica', size: 10 };
</script>

<template>
  <RouterView />
  <AppLoader v-if="!loaderStore.hasLoadedAndAnimated" />
  <div id="slideout" />
</template>

