import { createRouter, createWebHistory } from 'vue-router'

const TeamsView = () => import('@/views/TeamsView.vue')
const HiresView = () => import('@/views/HiresView.vue')
const LeadersView = () => import('@/views/LeadersView.vue')
const IntroView = () => import('@/views/IntroView.vue')
const AppView = () => import('@/views/AppView.vue')

import { useFiltersStore } from '@/stores/filters'
import { useCmsText } from "@/composables/useCmsText";

const router = createRouter({
  history: createWebHistory("/teamplan/"),
  routes: [
    {
      path: '/',
      name: 'Intro',
      component: IntroView,
    },
    {
      path: '/teams',
      component: AppView,
      name: 'Teams',
      children: [{
        path: '/teams',
        name: 'Teams',
        component: TeamsView
      }],
    },
    {
      path: '/hires',
      name: 'Hires',
      component: AppView,
      children: [{
        path: '/hires',
        name: 'Hires',
        component: HiresView
      }],
    },
    {
      path: '/leaders',
      name: 'Leaders',
      component: AppView,
      children: [{
        path: '/leaders',
        name: 'Leaders',
        component: LeadersView
      }],
    },
    { path: '/:pathMatch(.*)*', redirect: '/' },
  ]
})


// Handle URL hash data.
router.beforeEach((to, from, next) => {
  const filtersStore = useFiltersStore()
  useCmsText() // Initial call to get the CMS text to load.

  // Update the filters store with the hash parameters when they change.
  if (from.hash === to.hash) return next()

  const hash = `${to.hash}`.slice(1)
  const urlParams = Object.fromEntries(new URLSearchParams(hash))

  // Validate the URL parameters, and redirect to the correct URL if they're invalid.
  const correctParams = Object.entries(urlParams).reduce((acc, [key, value]) => {
    const filters = filtersStore.filters[key]
    const values = value.split(",")
    const valuesFiltered = values
      .filter(val => filters?.options.find(v => v.label === val))
      .filter(Boolean)

    const valuesArr = valuesFiltered
      .map(encodeURIComponent)
      .sort()
      .join(",")

    return `${acc}${key}=${valuesArr}&`
  }, '')

  if (hash !== correctParams) {
    const path = window.location.pathname.split('/').splice(2).join('/')
    router.replace({ path, hash: `#${correctParams}` })
    return next()
  }

  const newSelected = Object.entries(filtersStore.filters).reduce((acc, [key]) => {
    const values = urlParams[key]?.split(",").filter(Boolean)
    if (urlParams[key]) {
      return {
        ...acc,
        [key]: values
      }
    }

    if (filtersStore.filters[key]?.default[0] === "*") {
      return {
        ...acc,
        [key]: filtersStore.filters[key]?.options.map(o => o.value) || []
      }
    }

    return {
      ...acc,
      [key]: filtersStore.filters[key]?.default || []
    }
  }, { ...filtersStore.selected })

  if (JSON.stringify(filtersStore.selected) === JSON.stringify(newSelected)) return next()

  filtersStore.setUrlFilters(newSelected)

  next();
});

export default router
