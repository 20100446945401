import { type HeadcountStage } from "@/data/sources";
import isMobile from "@/utils/isMobile"
import { ref, watchEffect } from "vue";
import { useUserLoginStore } from '@/stores/userLogin'

export enum SlideoutBreakpoints {
  HIDDEN = 'hidden',
  PEEKING = 'peeking',
  VISIBLE = 'visible',
}

export class SlideoutController {
  currentBreakpoint: SlideoutBreakpoints
  _otherSlideouts: SlideoutController[] = []

  constructor() {
    this.currentBreakpoint = SlideoutBreakpoints.HIDDEN
  }

  setSlideouts(slideouts: SlideoutController[]) {
    this._otherSlideouts = slideouts.filter(s => s !== this)
  }

  open() {
    this._otherSlideouts.map(s => s.close())
    this.currentBreakpoint = SlideoutBreakpoints.VISIBLE
  }

  /**
  * Peeking is mobile only, desktop will fallback to being hidden
  **/
  peek() {
    this._otherSlideouts.map(s => s.close())
    if (isMobile()) {
      this.currentBreakpoint = SlideoutBreakpoints.PEEKING;
      return
    }
    this.currentBreakpoint = SlideoutBreakpoints.HIDDEN
  }

  close() {
    this.currentBreakpoint = SlideoutBreakpoints.HIDDEN
  }


  toggle() {
    if (this.currentBreakpoint === SlideoutBreakpoints.VISIBLE) {
      this.peek()
      return
    }
    this.open()
  }
}

export interface SlideoutTeamTarget {
  team: string;
  headcount: HeadcountStage;
}

export class BreakdownSlideoutController extends SlideoutController {
  teamTarget: SlideoutTeamTarget | undefined
  availableHeadcountStages: HeadcountStage[] = []
  element: HTMLElement | undefined

  constructor(
    target?: SlideoutTeamTarget,
  ) {
    super();
    this.teamTarget = target;
  }

  setElement(el: HTMLElement) {
    this.element = el
  }

  setTeamTarget(target: SlideoutTeamTarget) {
    // if the headcount is unavailable, set it to the last available headcount
    if (target.headcount && !this.availableHeadcountStages.includes(target.headcount)) {
      return
    }

    this.teamTarget = target
  }

  slideoutOpenOrPartial() {
    const isOpenOrPartial = ref(false)

    watchEffect(() => {
      if (this.currentBreakpoint === SlideoutBreakpoints.PEEKING ||
        this.currentBreakpoint === SlideoutBreakpoints.VISIBLE) {
        isOpenOrPartial.value = true
        return
      }
      isOpenOrPartial.value = false
    })

    return isOpenOrPartial
  }

  addAvailableHeadcount(headcount: HeadcountStage) {
    if (this.availableHeadcountStages.includes(headcount)) return
    this.availableHeadcountStages = [...this.availableHeadcountStages, headcount]
      .sort((a, b) => Number(a) - Number(b))
  }

  removeAvailableHeadcount(headcount: HeadcountStage) {
    // check if thie headcount is the current headcount, go to the last available headcount
    if (this.teamTarget?.headcount === headcount) {
      this.teamTarget = {
        ...this.teamTarget,
        headcount: this.availableHeadcountStages[this.availableHeadcountStages.length - 1]
      }
    }

    this.availableHeadcountStages = this.availableHeadcountStages.filter(h => h !== headcount)
  }

  scrollTop() {
    if (this.element) {
      this.element.scrollTop = 0
    }
  }

  open() {
    if (useUserLoginStore().popupOpen) return
    super.open()
  }
}

