import type { HiresMovement } from "./sources";

export const ivColors = {
  functionalBlack: '#181818',

  designGrey5: '#828181',

  baseBlue: '#6990F6',

  themeTint2: '#A5BCFA',

  themeShade2: '#3F5694',
  themeShade4: '#151D31',

  themeBlue500: '#6990F6',
  themeBlue700: '#3F5694',
  themeBlue900: '#151D31',
  supportNeutralBlack: '#161616',
  supportOffWhite: '#EEEBE5',
  supportGrey1: '#2B2B2B',

  // Index theme, orange
  theme100: '#FFE8E4',
  theme200: '#FFC2B9',
  theme300: '#FF9C8F',
  theme400: '#F87962',
  theme500: '#F15735',
  theme600: '#C7381E',
  theme700: '#9E1A08',

  technical900: '#2625DC',
  technical800: '#3532E6',
  technical700: '#4341F0',
  technical600: '#504DF0',
  technical500: '#726FFB',
  technical400: '#8A87FF',
  technical300: '#A7A5FF',
  technical200: '#CBCAFF',
  technical100: '#E8E8FF',

  gtm1100: '#7B5200',
  gtm1000: '#946000',
  gtm900: '#AD6E00',
  gtm800: '#C47B00',
  gtm700: '#DD890A',
  gtm600: '#F6AE05',
  gtm500: '#FFD029',
  gtm400: '#FFDE69',
  gtm300: '#FFE898',
  gtm200: '#FEF0BD',
  gtm100: '#FFF9E5',

  ga700: '#0D7838',
  ga600: '#209950',
  ga500: '#33AE64',
  ga400: '#41CC79',
  ga300: '#6CE59D',
  ga200: '#99F0BC',
  ga100: '#CCFFE1',

  operations700: '#D14376',
  operations600: '#EB719C',
  operations500: '#FF96BC',
  operations400: '#FFB2CE',
  operations300: '#FFC7DB',
  operations200: '#FFD9E7',
  operations100: '#FFEBF2',

  founders600: '#47ACCC',
  founders500: '#5ECAEC',
  founders400: '#87E2FF',
  founders300: '#A3E9FF',
  founders200: '#BDEFFF',
  founders100: '#D6F5FF',

  unknown: '#FF0000'
} as const

type ObjectValues<T> = T[keyof T];
export type IVColor = ObjectValues<typeof ivColors>;

// 700 is omitted below as the usual charts do not display these values
const technicalColors = [
  ivColors.technical600,
  ivColors.technical500,
  ivColors.technical400,
  ivColors.technical300,
  ivColors.technical200,
  ivColors.technical100
];

const subFunctionTechnicalColors = [
  ivColors.technical900,
  ivColors.technical800,
  ivColors.technical700,
  ...technicalColors
];

const gtmColors = [
  ivColors.gtm600,
  ivColors.gtm500,
  ivColors.gtm400,
  ivColors.gtm300,
  ivColors.gtm200,
  ivColors.gtm100
];

const subFunctionGtmColors = [
  ivColors.gtm1100,
  ivColors.gtm1000,
  ivColors.gtm900,
  ivColors.gtm800,
  ivColors.gtm700,
  ...gtmColors
]

const gaColors = [
  ivColors.ga600,
  ivColors.ga500,
  ivColors.ga400,
  ivColors.ga300,
  ivColors.ga200,
  ivColors.ga100
];

const operationsColors = [
  ivColors.operations600,
  ivColors.operations500,
  ivColors.operations400,
  ivColors.operations300,
  ivColors.operations200,
  ivColors.operations100
];

const foundersColors = [
  ivColors.founders600,
  ivColors.founders500,
  ivColors.founders400,
  ivColors.founders300,
  ivColors.founders200,
  ivColors.founders100
];

export const hiresColors = new Map<HiresMovement, IVColor>([
  ["Moved into Function", ivColors.theme700],
  ["Moved out of Function", ivColors.theme200],
  ["Hired", ivColors.theme500],
  ["Left Company", ivColors.theme300]
])

export const sortedPromotionsColors = new Map<string, IVColor>([
  ["Manager", ivColors.theme100],
  ["Director", ivColors.theme200],
  ["VP", ivColors.theme300],
  ["CXO", ivColors.theme400],
])

export const hiresTeamColors = new Map<string, IVColor[]>([
  // index is in the order [hired, moved into function, left company, moved out of function]
  ["Technical", [ivColors.technical500, ivColors.technical600, ivColors.technical400, ivColors.technical500]],
  ["GTM", [ivColors.gtm500, ivColors.gtm600, ivColors.gtm400, ivColors.gtm500]],
  ["G&A", [ivColors.ga500, ivColors.ga600, ivColors.ga400, ivColors.ga500]],
  ["Operations", [ivColors.operations500, ivColors.operations700, ivColors.operations400, ivColors.operations500]],
])

// Using a map as they are more reliably sorted
export const sortedTeamColors = new Map<string, IVColor[]>([
  ["Technical", technicalColors],
  ["GTM", gtmColors],
  ["G&A", gaColors],
  ["Operations", operationsColors],
])

export const sortedLeadersGroupColors = new Map<string, IVColor[]>([
  ["Founders", foundersColors],
  ...sortedTeamColors,
])

export const sortedSubfunctionTeamColors = new Map<string, IVColor[]>([
  ["Technical", subFunctionTechnicalColors],
  ["GTM", subFunctionGtmColors],
  ["G&A", gaColors],
  ["Operations", operationsColors],
  ["Founders", foundersColors]
])

export const teamColors = new Map<string, IVColor>([
  ["Technical", ivColors.technical500],
  ["GTM", ivColors.gtm500],
  ["G&A", ivColors.ga500],
  ["Operations", ivColors.operations500],
  ["Founders", ivColors.founders500]
])

export const sortedFunctionColorsGrouped = new Map<string, Map<string, IVColor>>([
  ["Technical", new Map([
    ["Engineering", ivColors.technical500],
    ["Product", ivColors.technical400],
    ["Design", ivColors.technical300],
    ["Analytics", ivColors.technical200],
  ])],
  ["GTM", new Map([
    ["Marketing", ivColors.gtm500],
    ["Sales & Bizdev", ivColors.gtm400],
    ["Customer Success", ivColors.gtm300],
    ["Commercial Management", ivColors.gtm200],
  ])],
  ["G&A", new Map([
    ["Finance", ivColors.ga600],
    ["HR", ivColors.ga500],
    ["Recruiting", ivColors.ga400],
    ["Legal", ivColors.ga300],
    ["Bizops/General Mgt", ivColors.ga200],
    ["Administration", ivColors.ga100],
  ])],
  ["Operations", new Map([
    ["Support", ivColors.operations500],
    ["Other Operations", ivColors.operations400],
  ])],
])

export const sortedFunctionColors = new Map<string, IVColor>([
  ...sortedFunctionColorsGrouped.get("Technical")!,
  ...sortedFunctionColorsGrouped.get("GTM")!,
  ...sortedFunctionColorsGrouped.get("G&A")!,
  ...sortedFunctionColorsGrouped.get("Operations")!,
])

