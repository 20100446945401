import { onMounted, ref } from 'vue'

export default function useWindowSize() {
  const width = ref(window.innerWidth)
  const height = ref(window.innerHeight)

  onMounted(() => {
    window.addEventListener('resize', async () => {
      // Chrome device simulation has a bug where the window size is pre-resize.
      setTimeout(() => {
        width.value = window.innerWidth
        height.value = window.innerHeight
      }, 1)
    })
  })

  return { width, height }
}
