import oneStdibs from './1stdibs.svg'
import twentyThreeandme from './23andme.svg'
import acvAuctions from './acv-auctions.svg'
import adyen from './adyen.svg'
import airbnb from './airbnb.svg'
import airtable from './airtable.svg'
import alan from './alan.svg'
import algolia from './algolia.svg'
import allbirds from './allbirds.svg'
import applyboard from './applyboard.svg'
import asana from './asana.svg'
import attackiq from './attackiq.svg'
import attentive from './attentive.svg'
import auto1Group from './auto1-group.svg'
import babylonHealth from './babylon-health.svg'
import backMarket from './back-market.svg'
import bark from './bark.svg'
import beamery from './beamery.svg'
import beautyPie from './beauty-pie.svg'
import beyondMeat from './beyond-meat.svg'
import bird from './bird.svg'
import blablacar from './blablacar.svg'
import bloomAndWild from './bloom-and-wild.svg'
import blueBottleCoffee from './blue-bottle-coffee.svg'
import bolt from './bolt.svg'
import bonobos from './bonobos.svg'
import booksy from './booksy.svg'
import braze from './braze.svg'
import brex from './brex.svg'
import built from './built.svg'
import bumble from './bumble.svg'
import calm from './calm.svg'
import cameo from './cameo.svg'
import canva from './canva.svg'
import capitolis from './capitolis.svg'
import cargoone from './cargoone.svg'
import carta from './carta.svg'
import carvana from './carvana.svg'
import casper from './casper.svg'
import catawiki from './catawiki.svg'
import cazoo from './cazoo.svg'
import celonis from './celonis.svg'
import checkout from './checkout.svg'
import chime from './chime.svg'
import chrono24 from './chrono24.svg'
import classpass from './classpass.svg'
import cockroachLabs from './cockroach-labs.svg'
import codat from './codat.svg'
import coinbase from './coinbase.svg'
import collibra from './collibra.svg'
import complyadvantage from './complyadvantage.svg'
import confluent from './confluent.svg'
import convoy from './convoy.svg'
import coupa from './coupa.svg'
import coursera from './coursera.svg'
import cultureAmp from './culture-amp.svg'
import curated from './curated.svg'
import curology from './curology.svg'
import dailyHarvest from './daily-harvest.svg'
import darktrace from './darktrace.svg'
import databricks from './databricks.svg'
import datadog from './datadog.svg'
import datarobot from './datarobot.svg'
import deliveroo from './deliveroo.svg'
import depop from './depop.svg'
import discord from './discord.svg'
import dollarShaveClub from './dollar-shave-club.svg'
import doordash from './doordash.svg'
import doximity from './doximity.svg'
import dropbox from './dropbox.svg'
import duolingo from './duolingo.svg'
import dutchie from './dutchie.svg'
import elastic from './elastic.svg'
import endClothing from './end-clothing.svg'
import equipmentshare from './equipmentshare.svg'
import etsy from './etsy.svg'
import everlane from './everlane.svg'
import expel from './expel.svg'
import faire from './faire.svg'
import farfetch from './farfetch.svg'
import farmersBusinessNetwork from './farmers-business-network.svg'
import farmersDog from './farmers-dog.svg'
import figma from './figma.svg'
import figs from './figs.svg'
import fuboTv from './fubo-tv.svg'
import fundingCircle from './funding-circle.svg'
import g2 from './g2.svg'
import getir from './getir.svg'
import gitlab from './gitlab.svg'
import glassdoor from './glassdoor.svg'
import glossier from './glossier.svg'
import glovo from './glovo.svg'
import goat from './goat.svg'
import gong from './gong.svg'
import goodEggs from './good-eggs.svg'
import goodrx from './goodrx.svg'
import goop from './goop.svg'
import gopuff from './gopuff.svg'
import grailed from './grailed.svg'
import groveCollaborative from './grove-collaborative.svg'
import grubmarket from './grubmarket.svg'
import guildEducation from './guild-education.svg'
import gusto from './gusto.svg'
import gympass from './gympass.svg'
import handshakeEducation from './handshake-education.svg'
import harrys from './harrys.svg'
import hashicorp from './hashicorp.svg'
import hims from './hims.svg'
import hipcamp from './hipcamp.svg'
import honey from './honey.svg'
import hopin from './hopin.svg'
import houzz from './houzz.svg'
import impossibleFoods from './impossible-foods.svg'
import indigo from './indigo.svg'
import instacart from './instacart.svg'
import joor from './joor.svg'
import justworks from './justworks.svg'
import kahoot from './kahoot.svg'
import keeptruckin from './keeptruckin.svg'
import klaviyo from './klaviyo.svg'
import kong from './kong.svg'
import kry from './kry.svg'
import latch from './latch.svg'
import leaflink from './leaflink.svg'
import lemonade from './lemonade.svg'
import lime from './lime.svg'
import lyst from './lyst.svg'
import masterclass from './masterclass.svg'
import materialBank from './material-bank.svg'
import messagebird from './messagebird.svg'
import mirakl from './mirakl.svg'
import miro from './miro.svg'
import monday from './monday.svg'
import monzo from './monzo.svg'
import motorway from './motorway.svg'
import n26 from './n26.svg'
import nextdoor from './nextdoor.svg'
import nexthink from './nexthink.svg'
import niantic from './niantic.svg'
import noom from './noom.svg'
import notion from './notion.svg'
import nuorder from './nuorder.svg'
import olo from './olo.svg'
import onetrust from './onetrust.svg'
import opendoor from './opendoor.svg'
import otrium from './otrium.svg'
import outschool from './outschool.svg'
import patreon from './patreon.svg'
import peloton from './peloton.svg'
import personio from './personio.svg'
import photobox from './photobox.svg'
import pillpack from './pillpack.svg'
import pilot from './pilot.svg'
import pinterest from './pinterest.svg'
import pitch from './pitch.svg'
import plaid from './plaid.svg'
import poshmark from './poshmark.svg'
import printify from './printify.svg'
import productboard from './productboard.svg'
import proseHaircare from './prose-haircare.svg'
import quora from './quora.svg'
import raisin from './raisin.svg'
import remote from './remote.svg'
import revolut from './revolut.svg'
import robinhood from './robinhood.svg'
import roblox from './roblox.svg'
import rohlik from './rohlik.svg'
import root from './root.svg'
import rothys from './rothys.svg'
import rover from './rover.svg'
import rubrik from './rubrik.svg'
import safetyculture from './safetyculture.svg'
import scale from './scale.svg'
import scopely from './scopely.svg'
import secretEscapes from './secret-escapes.svg'
import servicetitan from './servicetitan.svg'
import shopmonkey from './shopmonkey.svg'
import shutterstock from './shutterstock.svg'
import slack from './slack.svg'
import slicePizza from './slice-pizza.svg'
import sofi from './sofi.svg'
import sonos from './sonos.svg'
import spendesk from './spendesk.svg'
import spotify from './spotify.svg'
import squarespace from './squarespace.svg'
import stackOverflow from './stack-overflow.svg'
import starburst from './starburst.svg'
import stockx from './stockx.svg'
import strava from './strava.svg'
import stripe from './stripe.svg'
import supercell from './supercell.svg'
import swile from './swile.svg'
import tactileGames from './tactile-games.svg'
import talkdesk from './talkdesk.svg'
import taxfix from './taxfix.svg'
import tempoFitness from './tempo-fitness.svg'
import theHonestCompany from './the-honest-company.svg'
import theHutGroup from './the-hut-group.svg'
import thredup from './thredup.svg'
import thumbtack from './thumbtack.svg'
import toast from './toast.svg'
import tonal from './tonal.svg'
import trustpilot from './trustpilot.svg'
import turo from './turo.svg'
import typeform from './typeform.svg'
import udemy from './udemy.svg'
import uipath from './uipath.svg'
import vestiaireCollective from './vestiaire-collective.svg'
import vinted from './vinted.svg'
import vouch from './vouch.svg'
import vroom from './vroom.svg'
import wallapop from './wallapop.svg'
import warbyParker from './warby-parker.svg'
import whoop from './whoop.svg'
import wise from './wise.svg'
import wish from './wish.svg'
import wiz from './wiz.svg'
import wolt from './wolt.svg'
import zapier from './zapier.svg'
import zillow from './zillow.svg'
import ziprecruiter from './ziprecruiter.svg'
import zola from './zola.svg'

export default {
 '1stdibs.svg': oneStdibs,
 '23andme.svg': twentyThreeandme,
 'acv-auctions.svg': acvAuctions,
 'adyen.svg': adyen,
 'airbnb.svg': airbnb,
 'airtable.svg': airtable,
 'alan.svg': alan,
 'algolia.svg': algolia,
 'allbirds.svg': allbirds,
 'applyboard.svg': applyboard,
 'asana.svg': asana,
 'attackiq.svg': attackiq,
 'attentive.svg': attentive,
 'auto1-group.svg': auto1Group,
 'babylon-health.svg': babylonHealth,
 'back-market.svg': backMarket,
 'bark.svg': bark,
 'beamery.svg': beamery,
 'beauty-pie.svg': beautyPie,
 'beyond-meat.svg': beyondMeat,
 'bird.svg': bird,
 'blablacar.svg': blablacar,
 'bloom-and-wild.svg': bloomAndWild,
 'blue-bottle-coffee.svg': blueBottleCoffee,
 'bolt.svg': bolt,
 'bonobos.svg': bonobos,
 'booksy.svg': booksy,
 'braze.svg': braze,
 'brex.svg': brex,
 'built.svg': built,
 'bumble.svg': bumble,
 'calm.svg': calm,
 'cameo.svg': cameo,
 'canva.svg': canva,
 'capitolis.svg': capitolis,
 'cargoone.svg': cargoone,
 'carta.svg': carta,
 'carvana.svg': carvana,
 'casper.svg': casper,
 'catawiki.svg': catawiki,
 'cazoo.svg': cazoo,
 'celonis.svg': celonis,
 'checkout.svg': checkout,
 'chime.svg': chime,
 'chrono24.svg': chrono24,
 'classpass.svg': classpass,
 'cockroach-labs.svg': cockroachLabs,
 'codat.svg': codat,
 'coinbase.svg': coinbase,
 'collibra.svg': collibra,
 'complyadvantage.svg': complyadvantage,
 'confluent.svg': confluent,
 'convoy.svg': convoy,
 'coupa.svg': coupa,
 'coursera.svg': coursera,
 'culture-amp.svg': cultureAmp,
 'curated.svg': curated,
 'curology.svg': curology,
 'daily-harvest.svg': dailyHarvest,
 'darktrace.svg': darktrace,
 'databricks.svg': databricks,
 'datadog.svg': datadog,
 'datarobot.svg': datarobot,
 'deliveroo.svg': deliveroo,
 'depop.svg': depop,
 'discord.svg': discord,
 'dollar-shave-club.svg': dollarShaveClub,
 'doordash.svg': doordash,
 'doximity.svg': doximity,
 'dropbox.svg': dropbox,
 'duolingo.svg': duolingo,
 'dutchie.svg': dutchie,
 'elastic.svg': elastic,
 'end-clothing.svg': endClothing,
 'equipmentshare.svg': equipmentshare,
 'etsy.svg': etsy,
 'everlane.svg': everlane,
 'expel.svg': expel,
 'faire.svg': faire,
 'farfetch.svg': farfetch,
 'farmers-business-network.svg': farmersBusinessNetwork,
 'farmers-dog.svg': farmersDog,
 'figma.svg': figma,
 'figs.svg': figs,
 'fubo-tv.svg': fuboTv,
 'funding-circle.svg': fundingCircle,
 'g2.svg': g2,
 'getir.svg': getir,
 'gitlab.svg': gitlab,
 'glassdoor.svg': glassdoor,
 'glossier.svg': glossier,
 'glovo.svg': glovo,
 'goat.svg': goat,
 'gong.svg': gong,
 'good-eggs.svg': goodEggs,
 'goodrx.svg': goodrx,
 'goop.svg': goop,
 'gopuff.svg': gopuff,
 'grailed.svg': grailed,
 'grove-collaborative.svg': groveCollaborative,
 'grubmarket.svg': grubmarket,
 'guild-education.svg': guildEducation,
 'gusto.svg': gusto,
 'gympass.svg': gympass,
 'handshake-education.svg': handshakeEducation,
 'harrys.svg': harrys,
 'hashicorp.svg': hashicorp,
 'hims.svg': hims,
 'hipcamp.svg': hipcamp,
 'honey.svg': honey,
 'hopin.svg': hopin,
 'houzz.svg': houzz,
 'impossible-foods.svg': impossibleFoods,
 'indigo.svg': indigo,
 'instacart.svg': instacart,
 'joor.svg': joor,
 'justworks.svg': justworks,
 'kahoot.svg': kahoot,
 'keeptruckin.svg': keeptruckin,
 'klaviyo.svg': klaviyo,
 'kong.svg': kong,
 'kry.svg': kry,
 'latch.svg': latch,
 'leaflink.svg': leaflink,
 'lemonade.svg': lemonade,
 'lime.svg': lime,
 'lyst.svg': lyst,
 'masterclass.svg': masterclass,
 'material-bank.svg': materialBank,
 'messagebird.svg': messagebird,
 'mirakl.svg': mirakl,
 'miro.svg': miro,
 'monday.svg': monday,
 'monzo.svg': monzo,
 'motorway.svg': motorway,
 'n26.svg': n26,
 'nextdoor.svg': nextdoor,
 'nexthink.svg': nexthink,
 'niantic.svg': niantic,
 'noom.svg': noom,
 'notion.svg': notion,
 'nuorder.svg': nuorder,
 'olo.svg': olo,
 'onetrust.svg': onetrust,
 'opendoor.svg': opendoor,
 'otrium.svg': otrium,
 'outschool.svg': outschool,
 'patreon.svg': patreon,
 'peloton.svg': peloton,
 'personio.svg': personio,
 'photobox.svg': photobox,
 'pillpack.svg': pillpack,
 'pilot.svg': pilot,
 'pinterest.svg': pinterest,
 'pitch.svg': pitch,
 'plaid.svg': plaid,
 'poshmark.svg': poshmark,
 'printify.svg': printify,
 'productboard.svg': productboard,
 'prose-haircare.svg': proseHaircare,
 'quora.svg': quora,
 'raisin.svg': raisin,
 'remote.svg': remote,
 'revolut.svg': revolut,
 'robinhood.svg': robinhood,
 'roblox.svg': roblox,
 'rohlik.svg': rohlik,
 'root.svg': root,
 'rothys.svg': rothys,
 'rover.svg': rover,
 'rubrik.svg': rubrik,
 'safetyculture.svg': safetyculture,
 'scale.svg': scale,
 'scopely.svg': scopely,
 'secret-escapes.svg': secretEscapes,
 'servicetitan.svg': servicetitan,
 'shopmonkey.svg': shopmonkey,
 'shutterstock.svg': shutterstock,
 'slack.svg': slack,
 'slice-pizza.svg': slicePizza,
 'sofi.svg': sofi,
 'sonos.svg': sonos,
 'spendesk.svg': spendesk,
 'spotify.svg': spotify,
 'squarespace.svg': squarespace,
 'stack-overflow.svg': stackOverflow,
 'starburst.svg': starburst,
 'stockx.svg': stockx,
 'strava.svg': strava,
 'stripe.svg': stripe,
 'supercell.svg': supercell,
 'swile.svg': swile,
 'tactile-games.svg': tactileGames,
 'talkdesk.svg': talkdesk,
 'taxfix.svg': taxfix,
 'tempo-fitness.svg': tempoFitness,
 'the-honest-company.svg': theHonestCompany,
 'the-hut-group.svg': theHutGroup,
 'thredup.svg': thredup,
 'thumbtack.svg': thumbtack,
 'toast.svg': toast,
 'tonal.svg': tonal,
 'trustpilot.svg': trustpilot,
 'turo.svg': turo,
 'typeform.svg': typeform,
 'udemy.svg': udemy,
 'uipath.svg': uipath,
 'vestiaire-collective.svg': vestiaireCollective,
 'vinted.svg': vinted,
 'vouch.svg': vouch,
 'vroom.svg': vroom,
 'wallapop.svg': wallapop,
 'warby-parker.svg': warbyParker,
 'whoop.svg': whoop,
 'wise.svg': wise,
 'wish.svg': wish,
 'wiz.svg': wiz,
 'wolt.svg': wolt,
 'zapier.svg': zapier,
 'zillow.svg': zillow,
 'ziprecruiter.svg': ziprecruiter,
 'zola.svg': zola,
} as const
