import { onMounted, ref } from 'vue'
import { defineStore } from 'pinia'
import { useLoaderStore } from './loader'
import cmsJson from '@/assets/cmsText.json'

export const useCmsTextStore = defineStore('cmsText', () => {
  const loaderStore = useLoaderStore()
  const text = ref<{ [key: string]: string }>({})
  const ready = ref(false)

  loaderStore.addDependency(ready)

  onMounted(async () => {
    // const response = await fetch(new URL(cmsTextPath, import.meta.url).href)
    // text.value = await response.json()
    text.value = cmsJson
    ready.value = true
  })

  return { text, ready }
})
