import { defineStore } from "pinia";
import { ref, type Ref, watch } from "vue";

const MIN_LOAD_TIME = 1;

export const useLoaderStore = defineStore('loader', () => {
  // we don't want the loading to be too short
  const _pseudoLoaded = ref(false)

  const dependencies = ref([_pseudoLoaded])

  const dependenciesWithAnimation = ref([...dependencies.value])

  const _checkLoaded = () => dependencies.value.every((val) => val.value === true)

  const _checkLoadedAndAnimated = () =>
    _checkLoaded() && dependenciesWithAnimation.value.every((val) => val.value === true)

  const hasLoaded = ref(_checkLoaded())

  const hasLoadedAndAnimated = ref(_checkLoaded() && _checkLoadedAndAnimated())

  watch(() => dependenciesWithAnimation.value, () => {
    hasLoadedAndAnimated.value = _checkLoadedAndAnimated()
  }, { immediate: true, deep: true })

  watch(() => dependencies.value, () => {
    hasLoaded.value = _checkLoaded()
  }, { immediate: true, deep: true })

  const addAnimationDependency = (dep: Ref<boolean> | Ref<boolean>[]) => {
    if (Array.isArray(dep)) {
      dependenciesWithAnimation.value = [...dependenciesWithAnimation.value, ...dep]
      return
    }
    dependenciesWithAnimation.value = [...dependenciesWithAnimation.value, dep]
  }

  const addDependency = (dep: Ref<boolean> | Ref<boolean>[]) => {
    if (Array.isArray(dep)) {
      dependencies.value = [...dependencies.value, ...dep]
      addAnimationDependency(dep)
      return
    }

    dependencies.value = [...dependencies.value, dep]
    addAnimationDependency(dep)
  }

  const startMinLoadingTimer = (): Promise<void> =>
    new Promise((res) => {
      window.setTimeout(() => {
        _pseudoLoaded.value = true
        res()
      }, MIN_LOAD_TIME)
    })

  return {
    hasLoaded,
    hasLoadedAndAnimated,
    addDependency,
    addAnimationDependency,
    startPseudoLoading: startMinLoadingTimer
  }
})
