import teamsData from '@/data/sources/teams.json?raw';
import leadersData from '@/data/sources/leaders.json?raw';
import hiresData from '@/data/sources/hires.json?raw';
import subFunctionsData from '@/data/sources/subfunctions.json?raw';
import experienceData from '@/data/sources/experience.json?raw';
import foundersData from '@/data/sources/founders.json?raw';
import promotionsData from '@/data/sources/promotions.json?raw';
import companyHeadcountData from '@/data/sources/companyHeadcount.json?raw';

export interface CompanyDataset<T> {
  company: string;
  data: T;
}

export interface CompanyData<T> {
  datasets: CompanyDataset<T>[];
}

export interface Company {
  [key: string]: string;
  name: string;
  businessModel: string;
  foundingCohort: string;
  hqLocation: string;
}

export interface Functions<T> {
  [key: string]: T;
}

export interface Teams<T> {
  [key: string]: Functions<T>;
}

export const headcountStages = [
  '10',
  '50',
  '125',
  '250',
  '500',
] as const;

export type HeadcountStage = typeof headcountStages[number];

export const headcountStagesNumerical = [
  10,
  50,
  125,
  250,
  500,
] as const;

export const isHeadcountStage = (value: string): value is HeadcountStage =>
  headcountStages.includes(value as HeadcountStage);


// -- Leaders Data Types --

export type LeadersLeadershipLevel = {
  [key: string]: { [key in HeadcountStage]?: number } | undefined;
};

export type LeadersData = CompanyData<Teams<LeadersLeadershipLevel>>

// -- Teams Data Types --

export interface InnerTeamsData {
  [key: string]: number | Teams<number | undefined> | undefined;
  timeElapsed: number;
  capitalRaised: number;
  teams: Teams<number | undefined>
  teamAttrition?: number;
}

export interface TeamsData
  extends CompanyData<{
    [key: string]: InnerTeamsData | undefined;
  }> { }

// -- Hires Data Types --

export type HiresMovement = 'Moved into Function' | 'Moved out of Function' | 'Hired' | 'Left Company';

export const hiringStages = [
  "11-50",
  "51-125",
  "126-250",
  "251-500",
] as const

export type HiringStage = typeof hiringStages[number]


export const headcountStageMap = {
  '50': "11-50",
  '125': "51-125",
  '250': "126-250",
  '500': "251-500",
} as const

export const hiringStageMap = {
  '11-50': '50',
  '51-125': '125',
  '126-250': '250',
  '251-500': '500',
} as const

export type HiresMappableHeadcountStage = Exclude<HeadcountStage, '10'>

export type HeadcountHiringStage = typeof headcountStageMap[HiresMappableHeadcountStage]


export const isHiresMappableHeadcountStage = (value: string): value is HiresMappableHeadcountStage =>
  Object.prototype.hasOwnProperty.call(headcountStageMap, value as HiresMappableHeadcountStage);

export const isHeadcountStageMappableHires = (value: string): value is HeadcountHiringStage =>
  Object.prototype.hasOwnProperty.call(hiringStageMap, value as HeadcountHiringStage);

export const isHiresMovement = (value: string): value is HiresMovement =>
  value === 'Moved into Function'
  || value === 'Moved out of Function'
  || value === 'Hired'
  || value === 'Left Company';

export type HiresTeams = { [key in HiringStage]:
  Teams<{ [key: string]: number | undefined } | undefined> }

export interface HiresData extends CompanyData<HiresTeams> { }

// -- Subfunction Data Types --

// This is just for more readable types, having a function type in a function
// might be confusing

export interface SubFunctionData extends CompanyData<{
  [key in HeadcountStage]: Teams<number | undefined>;
}> { }

// -- Experience Data Types --

export type ExperienceType = 'Advanced 11+ Years' | 'Mid 6-10 Years' | 'Early 2-5 Years' | 'Entry 0-1 Years';

export const isExperienceType = (value: string): value is ExperienceType =>
  value === 'Advanced 11+ Years'
  || value === 'Mid 6-10 Years'
  || value === 'Early 2-5 Years'
  || value === 'Entry 0-1 Years';

export type ExperienceDataset = {
  [key: string]: { [key in HeadcountStage]: { [key in ExperienceType]?: number } }
}

export type ExperienceData = CompanyData<ExperienceDataset>

// -- Founders Data Types --

export type FounderType = 'Founder' | 'Non-Founder'

export const isFounderType = (value: string): value is FounderType =>
  value === 'Founder'
  || value === 'Non-Founder'

export type ExecRole = {
  [key: string]: { [key in HeadcountStage]?: number | undefined }
}

export type FoundersData = CompanyData<{ [key in FounderType]?: ExecRole }>

// -- Promotions Data Types --

export type PromotionsDataset = { [key in HiringStage]: Record<string, number | undefined> }

export type PromotionsData = CompanyData<PromotionsDataset>

// - Company Headcount Data Types -

export type CompanyHeadcount = CompanyData<Record<'headcount', HeadcountStage>>

/**
 * Represents the app data.
 */
const APP_DATA = {
  teams: JSON.parse(teamsData) as TeamsData,
  leaders: JSON.parse(leadersData) as LeadersData,
  hires: JSON.parse(hiresData) as HiresData,
  subFunctions: JSON.parse(subFunctionsData) as SubFunctionData,
  experience: JSON.parse(experienceData) as ExperienceData,
  founders: JSON.parse(foundersData) as FoundersData,
  promotions: JSON.parse(promotionsData) as PromotionsData,
  companyHeadcount: JSON.parse(companyHeadcountData) as CompanyHeadcount,
};

export default APP_DATA;
