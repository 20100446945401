<template>
  <div class="text-theme-tint2 text-sm uppercase">
    <div :style="`padding-left: ${prefixWidth}px;`" class="opacity-20" v-html="previousItem" />
    <div>
      <span ref="prefixRef" v-html="prefix" /><span v-html="currentItem" /><span v-html="suffix" />
    </div>
    <div :style="`padding-left: ${prefixWidth}px;`" class="opacity-20" v-html="nextItem" />
  </div>
</template>


<script setup lang="ts">
import { ref } from "vue";
import { watch, onMounted } from "vue";

const previousItem = ref<string>('')
const currentItem = ref<string>('')
const nextItem = ref<string>('')
const prefixWidth = ref<number>(0)
let scrollTimer: ReturnType<typeof setTimeout>;
let currentIndex = 0

const prefixRef = ref<HTMLElement | undefined>()

const props = defineProps<{
  textItems: string[]
  prefix: string
  suffix: string
  duration: number
}>()

watch(() => prefixRef.value, () => {
  if (prefixRef.value?.offsetWidth) {
    prefixWidth.value = prefixRef.value?.offsetWidth
  }
})

currentItem.value = props.textItems[0]
nextItem.value = props.textItems[1]

const scroll = () => {
  if (currentIndex >= props.textItems.length) {
    clearInterval(scrollTimer)
    return
  }

  previousItem.value = currentItem.value
  currentItem.value = nextItem.value
  nextItem.value = props.textItems[currentIndex + 1] || '&nbsp;'
  currentIndex++
}

onMounted(() => {
  scrollTimer = setInterval(scroll, props.duration / props.textItems.length)
})
</script>

